





























import { Vue, Component } from 'vue-property-decorator';
import Notice from '@/views/index/comm/Notice.vue';
import Recommend from '@/views/index/comm/Recommend.vue';
import { DictItem, getDictConfig } from '@/api/other';

import dayjs from 'dayjs';
@Component({
  name: '',
  components: { Notice, Recommend }
})
export default class extends Vue {
  vajraArea: DictItem[] = [];

  get today() {
    return dayjs().day();
  }

  async getVajraArea() {
    const { payload } = await getDictConfig('VAJRA_AREA');
    this.vajraArea = payload;
  }

  created() {
    this.getVajraArea();
  }
}
